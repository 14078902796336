import React from 'react'
import { graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {FormattedMessage} from 'react-intl'


const Lavori = ({ data, pageContext }) => {
    const { locale } = pageContext;
    const lavoro = data.lavoro;
    const immagini = data.lavoro.immagini || [];
    const immagineHero = immagini && immagini[0];
    return (
        <Layout locale={locale}>
            <SEO title={lavoro.titolo} immagineHero={immagineHero}/>
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="pagetitle">{lavoro.titolo}</h1>
                </div>
            </div>
            {!!lavoro.testo &&
                <div className="section section--top">
                    <div className="row">
                        <div className="col-12">
                            <div className="section__text"  dangerouslySetInnerHTML={{__html: lavoro.testo.childMarkdownRemark.html}} />
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                {immagini.map((item,index) => {
                    return (
                        <div key={index} className="col-sm-6  col-md-4">
                            <Img alt={item.title} title={item.title} className="card" fluid={item.fluid} />
                        </div>
                    )
                })}
            </div>
            <div className="section">
                <div className="row">
                    <div className="col-12 text-center">
                        <FormattedMessage id="tornaailavori">
                            {txt =>  <Link to="/i-miei-lavori" className="btn-action">{txt}</Link>}
                        </FormattedMessage>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query LavoriQuery($contentful_id: String!, $locale: String!) {
        lavoro: contentfulLavori(contentful_id: {eq: $contentful_id},  node_locale: {eq: $locale}){
            titolo
            testo {
                childMarkdownRemark {
                    html
                }
            }
            immagini {
                title
                fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp
                }
            }
        }  
    }
`;

export default Lavori;
